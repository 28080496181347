<template>
	<nav class="nav nav--fit">
		<div class="nav__wrap">
			<a class="nav__btn nav__btn--prev" @click="goBack">
				<i class="icon icon-left">이전 페이지</i>
			</a>
			<p class="nav__item">
				<span class="nav__text">
					스탬프
				</span>
			</p>
			<a class="nav__btn nav__btn--map">
			</a>
		</div>
	</nav>
	<div id="container" class="container">
		<div id="sub" class="sub mypage">
			<section class="content">
				<div class="box">
					<!-- tab -->
					<div class="tab tab--half tab--fit">
						<a :class="{'active': stampTab === 0}" @click="stampTab = 0">스탬프카드</a>
						<a :class="{'active': stampTab === 1}" @click="stampTab = 1">적립내역</a>
					</div>
					<!-- 스탬프 -->
					<div class="stamp" v-show="stampTab === 0">
						<!-- 스탬프 상단 -->
						<div class="stamp__top">
							<div class="mypage__my">
								<div class="mypage__my-info">
									<div class="mypage__my-name">
										<span v-if="isLogin">
											<strong>{{userData.nick_nm}}</strong>님의<br>
											스탬프 현황
										</span>
									</div>
								</div>
								<div class="mypage__my-point">
									<strong>{{stamp}}</strong>
									<span>
										/5개
									</span>
								</div>
							</div>
						</div>
						<!-- 스탬프 현황 -->
						<div class="stamp__list">
							<span class="stamp__item" :class="{'active': stamp >= index}" v-for="index in 4" :key="index">
								<!-- [D] 스탬프 찍었을때 아이콘 표시 -->
								<i class="icon" :class="urlhost !== 'www.hellostay.co.kr' ? 'icon-H_SALAD' : 'icon-H_stay'" v-if="stamp >= index"></i>
								<template v-else>
									{{index}}회
								</template>
							</span>
							<span class="stamp__item">
								5000P
							</span>
						</div>
						<!-- 스탬프 적립 -->
						<div class="stamp__save">
							스탬프 5회 적립 시 5000P 적립!
						</div>
						<!-- 스탬프 방법 -->
						<div class="stamp__info">
							<h3 class="stamp__info-title">
								<i class="icon icon-stamp--point"></i>
								스탬프 획득 방법
							</h3>
							숙박 퇴실 24시간 후, 스탬프 1회 자동 적립!
						</div>
						<!-- 스탬프 안내 -->
						<div class="stamp__notice">
							<h3 class="stamp__notice-title">
								스탬프 유의사항 안내
							</h3>
							<ul class="notice-list">
								<li>
									1. 퇴실 이후 스탬프가 자동 적립됩니다
									<span>(적립 시점 : 퇴실일 기준 익일 00시 적용)</span>
								</li>
								<li>
									2. 스탬프 5회 적립 시 5000P 적립!
								</li>
								<li>
									3. 숙박 상품 이용시에만 스탬프가 적립됩니다.
								</li>
								<li>
									4. 지급된 포인트 유효기간은 180일 입니다.
								</li>
								<li>
									5. 스탬프 정책은 상황에 따라 달라질 수 있습니다.
								</li>
							</ul>
						</div>
					</div>
					<div class="stamplist" v-show="stampTab === 1">
						<!-- 스탬프 현황 -->
						<div class="stamp__status">
							<div class="stamp__box">
								<i class="icon icon-stamp"></i><br>
								누적 적립스탬프
								<strong>{{totalStamp}}개</strong>
							</div>
							<div class="stamp__box">
								<i class="icon icon-point2"></i><br>
								누적 적립포인트
								<strong>{{totalPoint}}P</strong>
							</div>
						</div>
						<!-- 스탬프 적립내역 -->
						<ul class="info info--small">
							<li class="subject">
								<h4 class="info__subject" style="padding-left:34px">
									적립일
								</h4>
								<h4 class="info__subject">
									적립내역
								</h4>
							</li>
							<li v-for="(item, index) in mmy0122" :key="index">
								<h4 class="info__title">
									{{dayjs(item.STAMP_DATE).format('YYYY.MM.DD')}}
								</h4>
								<div class="info__wrap">
									<div class="info__text">
										{{item.STAMP_DESC.split(" ")[0]}}
										<strong class="black">{{item.STAMP_DESC.split(" ")[1]}}</strong>
									</div>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</section>
		</div>
	</div>
	<div id="navigation" class="navigation navigation--noborder" v-show="stampTab === 1">
			<div class="pager">
				<a @click="onClickStartPage">
					<i class="icon icon-first"></i>
				</a>
				<a @click="onClickPrevPage">
					<i class="icon icon-left--light"></i>
				</a>
				<a 
					v-for="index in Math.min(maxPage, 5)" 
					:key="index" 
					:class="{'active' : (page === pageOffset + index)}"
					@click="onClickMovePage(pageOffset + index)"
				>
					{{pageOffset + index}}
				</a>
				<a @click="onClickNextPage">
					<i class="icon icon-right--light"></i>
				</a>
				<a @click="onClickEndPage">
					<i class="icon icon-last"></i>
				</a>
			</div>
	</div>
</template>

<script>
import { useStore } from "vuex";
import { computed, onMounted, ref} from "vue";
import { useRouter } from 'vue-router';
import dayjs from 'dayjs'
import "dayjs/locale/ko";

dayjs.locale("ko");
export default {
	setup: function() {
		const store = useStore();
		const router = useRouter();

		const userData = computed(() => store.state.userData);
		const isLogin = computed(() => Object.keys(userData.value).length !== 0);
		const mmy0101 = computed(() => store.state.mmy01.mmy0101);
		const mmy0122 = computed(() => store.state.mmy01.mmy0122);

		const totalPoint = computed(()=> mmy0122.value.length > 0	? mmy0122.value[0].SUM_POINT.toLocaleString("en-US") : 0);
		const totalStamp = computed(()=> mmy0122.value.length > 0	? mmy0122.value[0].SUM_STAMP.toLocaleString("en-US") : 0);
		const totalItem = computed(()=> mmy0122.value.length > 0	? mmy0122.value[0].SUM_STAMP : 0);
		const maxPage = computed(() => Math.ceil(totalItem.value / 10));
		const page = ref(1);
		const startPage = computed(() => (page.value - 1)/5 * 5 + 1);
		const endPage = computed(() => Math.min(startPage.value + 4, maxPage.value));
		const pageOffset = computed(() => Math.max(Math.min(page.value - 3, maxPage.value - 5), 0));

		const stamp = computed(() => 
			isLogin.value && mmy0101?.value[0]?.stamp_cnt !== undefined
				? mmy0101?.value[0]?.stamp_cnt % 5
				: "-"
		);
		
		const urlhost = ref();

		const stampTab = ref(0);

		onMounted(()=>{
			
			urlhost.value = store.state.app.urlhost;
			if(isLogin.value) {
					store.dispatch("mmy01/fetchMmy0122", {
						proc_cd: "01",
						mem_id: userData.value.mem_token,
						pagesize: 10,
						gotopage: 1
					});
				if(mmy0101.value.length <= 0) {
					store.dispatch("mmy01/fetchMmy0101", {
						proc_cd: "01",
						mem_id: userData.value.mem_token
					});
				}
			}
		});


		const updatePage = (index) => {
			if(page.value !== index){
				store.dispatch("mmy01/fetchMmy0122", {
					proc_cd: "01",
					mem_id: userData.value.mem_token,
					pagesize: 10,
					gotopage: index
				});
				page.value = index;
			}
		}

		const onClickMovePage = (index) => {
			updatePage(index);
		}

		const onClickNextPage = () => {
			if(page.value < maxPage.value){
				updatePage(page.value + 1);
			}
		}
		const onClickPrevPage = () => {
			if(page.value > 1){
				updatePage(page.value - 1);
			}
		}	

		const onClickEndPage = () => {
			updatePage(maxPage.value);
		}
		const onClickStartPage = () => {
			updatePage(1);
		}

		const goBack = () => {
			router.go(-1);
		};

		return {
			goBack,
			dayjs,
			mmy0101,
			mmy0122,
			stamp,
			stampTab,
			userData,
			isLogin,
			totalItem,
			maxPage,
			page,
			onClickMovePage,
			onClickNextPage,
			onClickPrevPage,
			onClickEndPage,
			onClickStartPage,
			startPage,
			endPage,
			pageOffset,
			totalPoint,
			totalStamp,
			urlhost
		};
	}
}
</script>